export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomSouthAfricaEnglish: 'vc-za-en',
    EtisalatPakistanEnglish: 'et-pk-en',
    VodacomEvinaSouthAfricaEnglish: 'vc-encrypt-za-en',
};

brandConfigObjectMap.set(Brands.VodacomSouthAfricaEnglish, {
    gameTitle: 'Cricket Zone',
    freePlayServiceId: 'cricket-free',
    baseRoute: '/home'
});

brandConfigObjectMap.set(Brands.EtisalatPakistanEnglish, {
    gameTitle: 'Cricket Zone',
    freePlayServiceId: 'cricket-free',
    baseRoute: '/home'
});

