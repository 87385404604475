import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class FantasyService {
    static async getNews(type) {
        const failSafeType = type ? type : 'composite'
        const response = await axios.post(`${environmentConfig.fantasyService}/news/get-news-feed-items`, {
            sportId: 3,
            type: failSafeType
        })
        return response.data.recordset
    }

    static async autoPickOptIn(userToken, msisdn, isOptIn) {
        return axios.post(`${environmentConfig.fantasyService}/soccer/auto-pick-opt-in`, {
            token: userToken,
            isOptIn: isOptIn,
            msisdn: msisdn
        })
    }
    static async submitCaptain(roundId, msisdn, sportId, playerId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/update-captain`, {
            roundId,
            msisdn,
            sportId,
            playerId
        })
        return response.data
    }

    static async postEntries(entries) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/post-user-entries`, {
            entries
        })
        return response.data
    }

    static async getUserEntries(roundId, msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-user-entries`, {
            msisdn,
            roundId,
        })
        return response.data.recordset
    }

    static async deleteUserEntries(roundId, msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/delete-user-entries`, {
            msisdn,
            roundId,
        })
        return response.data.recordset
    }

    static async getRoundPlayers(position, roundId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-round-players`, {
            position,
            roundId,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 3,
        })
        return response.data
    }

    static async getUpcomingFixtures() {
        const response = await axios.post(`${environmentConfig.fantasyService}/fixtures/get-upcoming-fixtures`, {
            sportId: 3,
        })
        return response.data
    }

    static async getUserLandingStats(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/profile/get-user-stats`, {
            msisdn: msisdn,
            sportId: 3,
        })
        return response.data
    }

    static async getCricketRounds(msisdn, roundId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-upcoming-rounds`, {
            msisdn: msisdn,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 3,
            roundId: roundId
        })
        return response.data
    }

    static async getActiveLeagues() {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-leagues`, {
            sportId: 3,
        })
        return response.data
    }

    static async getTopPicks(leagueAbbriviation, season) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-top-players-in-league`, {
            ref: leagueAbbriviation,
            seasonRef: season,
            sportId: 3,
        })
        return response.data
    }

    static async getRoundLeaders(msisdn, id) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-round-leaders`, {
            id,
            msisdn,
            sportId: 3,
            userAccessId: 9
        })
        return response.data
    }

    static async getCompletedRounds(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-completed-rounds`, {
            msisdn: msisdn,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 3,
        })
        return response.data
    }
}

