import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import store from "@/store";

const cricketFantasy = 1

export default class PredictorService {
    static async getActiveSports(msisdn, username, avatar) {
        return await axios.post(`${environmentConfig.predictorService}/player-landing-page`, {
            msisdn: msisdn,
            username: username,
            avatar,
            cricketFantasy: cricketFantasy,
        })
    }

    static async getUserStats(msisdn) {
        const response = await axios.post(`${environmentConfig.predictorService}/get-user-stats`, {
            msisdn: msisdn,
            cricketFantasy: cricketFantasy,
        })
        return response.data;
    }

    static async getPlayerResults(msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/get-players-winnings`, {
            msisdn: msisdn,
            cricketFantasy: cricketFantasy,
        })
    }

    static async getPlayerRoundPredictions(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/get-player-round-predictions`, {
            msisdn: msisdn,
            roundId: roundId,
            cricketFantasy: cricketFantasy,
        })
    }

    static async getPlayerRoundHistory(msisdn) {
        const response = await axios.post(`${environmentConfig.predictorService}/get-player-round-history`, {
            msisdn: msisdn,
            sportId: 3,
            cricketFantasy: cricketFantasy,
        })
        return response.data.recordsets[0];
    }

    static async enterPredictions(msisdn, fixture, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/cricket/enter-predictions`, {
            msisdn: msisdn,
            roundId: roundId || store.state.roundId,
            cricketFixtureId: fixture.cricketFixtureId,
            eventTypeId: 1,
            teamId: fixture.teamId,
            battingWinMargin: fixture.battingWinMargin,
            bowlingWinMargin: fixture.bowlingWinMargin || 0,
            cricketFantasy: cricketFantasy,
        })
    }

    static async getRoundLeaderboard(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/get-leaderboard`, {
            msisdn: msisdn,
            roundId: roundId,
            cricketFantasy: cricketFantasy,
        })
    }

    static async getLeagueFixtures(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/round-fixtures`, {
            msisdn: msisdn,
            roundId: roundId,
        })
    }
}

